import React from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { TrendingUp, TrendingDown, Users, BookOpen, Star, ArrowRight } from 'lucide-react';
import { Tooltip, Legend, } from 'recharts';
import { Layout, Pen, Code, Rocket, MoreVertical, FileText, Trash2 } from 'lucide-react';

const AdminDashboard = () => {
    const registrationData = Array.from({ length: 12 }, (_, i) => ({
        name: `Day ${i + 1}`,
        paid: Math.floor(Math.random() * 50) + 20,
        free: Math.floor(Math.random() * 30) + 10,
    }));

    const courseActivity = [
        { name: 'Design', paid: 25, free: 12 },
        { name: 'Marketing', paid: 15, free: 5 },
        { name: 'Business', paid: 20, free: 18 },
        { name: 'Web Dev', paid: 10, free: 3 },
        { name: 'Productivity', paid: 8, free: 2 },
    ];

    const supportTickets = [
        { name: 'Robert Fox', message: 'Duis at consectetur lorem donec massa...', time: '10:00 pm' },
        { name: 'Emily Johnson', message: 'Vivamus suscipit tortor eget felis porttitor...', time: '11:15 am' },
        { name: 'Michael Brown', message: 'Curabitur aliquet quam id dui posuere blandit...', time: '09:30 am' },
        { name: 'Sarah Williams', message: 'Nulla quis lorem ut libero malesuada feugiat...', time: '02:45 pm' },
    ];
    const categories = [
        { name: 'Graphic Design', icon: <Pen className="w-6 h-6" />, courses: '90+ Courses', trend: 'up' },
        { name: 'UI/UX Design', icon: <Layout className="w-6 h-6" />, courses: '90+ Courses', trend: 'down' },
        { name: 'Web Development', icon: <Code className="w-6 h-6" />, courses: '90+ Courses', trend: 'up' },
        { name: 'Digital Marketing', icon: <Rocket className="w-6 h-6" />, courses: '90+ Courses', trend: 'up' },
    ];


    const instructorCourses = [
        {
            name: 'Ronald Richards',
            avatar: '/api/placeholder/40/40',
            courses: '90+ Courses',
            courseName: 'Web Design',
            publishDate: '2024-02-09',
            enrolled: '3.6K+',
            progress: 46,
            rating: 4.8
        },
        {
            name: 'Jane Doe',
            avatar: '/api/placeholder/40/40',
            courses: '120+ Courses',
            courseName: 'Graphic Design',
            publishDate: '2023-11-12',
            enrolled: '4.2K+',
            progress: 75,
            rating: 4.9
        },
        {
            name: 'John Smith',
            avatar: '/api/placeholder/40/40',
            courses: '80+ Courses',
            courseName: 'Data Science',
            publishDate: '2024-01-20',
            enrolled: '2.9K+',
            progress: 65,
            rating: 4.7
        },
        {
            name: 'Emily Johnson',
            avatar: '/api/placeholder/40/40',
            courses: '60+ Courses',
            courseName: 'Machine Learning',
            publishDate: '2023-12-15',
            enrolled: '3.1K+',
            progress: 85,
            rating: 4.6
        },
        {
            name: 'Michael Brown',
            avatar: '/api/placeholder/40/40',
            courses: '50+ Courses',
            courseName: 'Digital Marketing',
            publishDate: '2023-10-18',
            enrolled: '3.4K+',
            progress: 78,
            rating: 4.7
        },
        {
            name: 'Sarah Lee',
            avatar: '/api/placeholder/40/40',
            courses: '70+ Courses',
            courseName: 'Content Creation',
            publishDate: '2024-03-22',
            enrolled: '5.0K+',
            progress: 90,
            rating: 4.9
        }
    ];



    return (
        <div className="min-h-screen bg-gray-100 dark:bg-slate-900 text-slate-900 dark:text-white p-4 md:p-6 lg:p-8">
            {/* Header */}
            <div className="mb-6">
                <h1 className="text-2xl font-bold text-gray-800 dark:text-white  text-left">Admin Dashboard</h1>
                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>Admin Dashboard</span>
                </div>
            </div>

            {/* Stats Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                {/* Revenue Card */}
                <div className="bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white p-6 rounded-lg shadow-sm">
                    <div className="flex justify-between items-start mb-4">
                        <div>
                            <p className="text-gray-500 mb-1">Overall revenue</p>
                            <h3 className="text-2xl font-bold">$30,000</h3>
                            <p className="text-sm text-blue-600">09% Below Target</p>
                        </div>
                        <span className="text-blue-600"><TrendingUp size={20} /></span>
                    </div>
                    <div className="h-16">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={registrationData}>
                                <Line type="monotone" dataKey="paid" stroke="#2563eb" strokeWidth={2} dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Registrations Card */}
                <div className="bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white p-6 rounded-lg shadow-sm">
                    <div className="flex justify-between items-start mb-4">
                        <div>
                            <p className="text-gray-500 mb-1">Total registrations</p>
                            <h3 className="text-2xl font-bold">21,000</h3>
                            <p className="text-sm text-red-600">05% Below Target</p>
                        </div>
                        <span className="text-red-600"><TrendingDown size={20} /></span>
                    </div>
                    <div className="h-16">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={registrationData}>
                                <Line type="monotone" dataKey="free" stroke="#dc2626" strokeWidth={2} dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Courses Card */}
                <div className="bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white p-6 rounded-lg shadow-sm">
                    <div className="flex justify-between items-start mb-4">
                        <div>
                            <p className="text-gray-500 mb-1">Total courses</p>
                            <h3 className="text-2xl font-bold">25,000</h3>
                            <p className="text-sm text-blue-600">50% Below Target</p>
                        </div>
                        <span className="text-blue-600"><BookOpen size={20} /></span>
                    </div>
                    <div className="h-16">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={registrationData}>
                                <Line type="monotone" dataKey="paid" stroke="#2563eb" strokeWidth={2} dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Reviews Card */}
                <div className="bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white p-6 rounded-lg shadow-sm">
                    <div className="flex justify-between items-start mb-4">
                        <div>
                            <p className="text-gray-500 mb-1">Average review</p>
                            <h3 className="text-2xl font-bold">4.5</h3>
                            <p className="text-sm text-blue-600">05% Below Target</p>
                        </div>
                        <span className="text-blue-600"><Star size={20} /></span>
                    </div>
                    <div className="h-16">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={registrationData}>
                                <Line type="monotone" dataKey="paid" stroke="#2563eb" strokeWidth={2} dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>

            {/* Charts and Lists Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                {/* Registration Rate Chart */}
                <div className="bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white p-6 rounded-lg shadow-sm lg:col-span-2">
                    <div className="flex justify-between items-center mb-6">
                        <h3 className="text-lg font-semibold">Registration Rate</h3>
                        <select className="text-sm border rounded-md px-2 py-1 dark:text-slate-900 ">
                            <option>This Year</option>
                            <option>Last Year</option>
                        </select>
                    </div>
                    <div className="h-64">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={registrationData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Line type="monotone" dataKey="paid" stroke="#2563eb" />
                                <Line type="monotone" dataKey="free" stroke="#22c55e" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Popular Categories */}
                <div className="bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white p-6 rounded-lg shadow-sm">
                    <div className="flex justify-between items-center mb-6">
                        <h3 className="text-lg font-semibold">Popular Categories</h3>
                        <span className="text-sm text-gray-500">07 Days</span>
                    </div>
                    <div className="space-y-4">
                        {['Graphic Design', 'UI/UX Design', 'Web Development', 'Digital Marketing'].map((category) => (
                            <div key={category} className="flex items-center justify-between p-3 bg-gray-100 dark:bg-slate-900 text-slate-900 dark:text-white rounded-lg hover:bg-gray-100">
                                <div className="flex items-center space-x-3">
                                    <div className="w-8 h-8 bg-blue-100 rounded-lg flex items-center justify-center">
                                        <BookOpen size={16} className="text-blue-600" />
                                    </div>
                                    <div>
                                        <p className="font-medium">{category}</p>
                                        <p className="text-sm text-gray-500">90+ Courses</p>
                                    </div>
                                </div>
                                <ArrowRight size={16} className="text-gray-400" />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Course Activity */}
                <div className="bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white p-6 rounded-lg shadow-sm">
                    <div className="flex justify-between items-center mb-6">
                        <h3 className="text-lg font-semibold">Course Activity</h3>
                        <span className="text-sm text-gray-500">07 Days</span>
                    </div>
                    <div className="h-64">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={courseActivity}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Bar dataKey="paid" fill="#2563eb" />
                                <Bar dataKey="free" fill="#22c55e" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Support Tickets */}
                <div className="bg-gray-200 dark:bg-slate-800 text-slate-900 dark:text-white p-6 rounded-lg shadow-sm">
                    <div className="flex justify-between items-center mb-6">
                        <h3 className="text-lg font-semibold text-left">Recent Support Tickets</h3>
                        <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">See all</button>
                    </div>
                    <div className="space-y-4 text-left">
                        {supportTickets.map((ticket, index) => (
                            <div key={index} className="flex items-start space-x-3">
                                <img
                                    src="https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=400"
                                    alt={ticket.name}
                                    className="w-10 h-10 rounded-full"
                                />
                                <div>
                                    <h4 className="font-medium">{ticket.name}</h4>
                                    <p className="text-sm text-gray-500 line-clamp-1">{ticket.message}</p>
                                    <p className="text-xs text-gray-400 mt-1">{ticket.time}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>




            </div>
        </div>
    );
};

export default AdminDashboard;




