import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { HiOutlineSearch } from 'react-icons/hi';
import ThemeSwitcher from './ThemeSwitcher';

const Dheader = () => {
    const [searchVisible, setSearchVisible] = useState(false);

    const toggleSearchBar = () => {
        setSearchVisible(!searchVisible);
    };

    return (
        <header className="bg-white dark:bg-slate-950 text-gray-800 dark:text-white px-4 py-3 flex justify-between items-center rounded-lg">

            {/* Logo for Mobile/Tablet */}
            <div className="flex lg:hidden">
                <a href="#" className="header-logo h-8 w-auto">
                    {/* Optional logo */}
                </a>
            </div>

            {/* Greeting for Desktop */}
            <div className="hidden lg:flex items-center text-lg font-semibold">
                <span className="mr-2">👋</span>
                <span className="text-blue-600 dark:text-purple-600">Welcome back,</span>
                <span className="font-extrabold ml-1 text-2xl">Tanisha!</span>
            </div>

            {/* Search Bar, Profile, and Theme Switcher */}
            <div className="flex items-center space-x-4 relative">

                {/* Search Bar with Icon for large screens */}
                <div className="hidden lg:flex w-full max-w-md relative">
                    <InputText
                        placeholder="Search..."
                        className="w-full bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-white rounded-lg p-2 pl-10"
                    />
                    <HiOutlineSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-800 dark:text-white" size={20} />
                </div>

                {/* Search Icon for small screens */}
                <div className="lg:hidden">
                    <button onClick={toggleSearchBar} className="focus:outline-none" aria-label="Toggle search bar">
                        <HiOutlineSearch size={24} className="text-gray-800 dark:text-white" />
                    </button>
                </div>

                {/* Toggleable search bar for small screens */}
                {searchVisible && (
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-full max-w-md px-4 transition-all duration-300 ease-in-out z-50 lg:hidden">
                        <InputText
                            placeholder="Search..."
                            className="w-full bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-white rounded-lg p-2 "
                        />
                    </div>
                )}

                {/* User Avatar */}
                <div className="relative">
                    <a href="#" className="h-8 w-8 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full flex items-center justify-center text-white font-bold">
                        L
                    </a>
                    {/* Optional Tooltip for avatar */}
                    <div className="absolute hidden group-hover:block bg-black text-white text-xs p-2 rounded-lg right-0">
                        Profile
                    </div>
                </div>

                {/* Theme Switcher */}
                <ThemeSwitcher />
            </div>
        </header>
    );
};

export default Dheader;
