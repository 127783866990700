import React from 'react';

import LMSPage from '../pages/LMSPage';
import Sidebar from './Sidebar';
import Dheader from './Dheader';


const Lms = () => {
    return (
        <div className="dashboard-container flex">
            < Sidebar /> {/* Sidebar will render on the side */}
            <div className="flex-1 p-6">
                {/* Header and main content */}
                <div className="md:ml-64">
                    <Dheader /> {/* Adjust margin as needed */}
                </div>
                <div className="md:ml-64">
                    <LMSPage /> {/* Render LMSPage */}
                </div>
            </div>
        </div>
    );
};

export default Lms;
