

import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { PanelMenu } from 'primereact/panelmenu';
import ThemeSwitcher from './ThemeSwitcher';

const Header = () => {
    const [visible, setVisible] = useState(false);

    const items = [

    ];

    const buttonStyle = `
    text-transparent bg-clip-text bg-gradient-to-r 
    from-cyan-400 via-purple-500 to-purple-600 
    hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
    focus:ring-purple-300 dark:focus:ring-purple-800 
    font-bold rounded-lg text-xl px-4 py-2  {/* Changed from text-lg to text-xl */}
    text-center me-2 mb-2
`;

    const signupButtonStyle = `
    text-transparent bg-clip-text bg-gradient-to-r 
    from-purple-400 via-purple-500 to-purple-600 
    hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
    focus:ring-cyan-300 dark:focus:ring-cyan-800 
    font-bold rounded-lg text-xl px-4 py-2  {/* Changed from text-lg to text-xl */}
    text-center me-2 mb-2
`;



    const start = (
        <a href="/" className="header-logo h-8 w-auto" />
    );

    const end = (
        <div className="flex items-center gap-2">
            <div className="hidden lg:flex items-center gap-4 -ml-12"> {/* Adjusted negative margin for 3 times left */}
                <button
                    type="button"
                    className="text-purple-500 hover:text-cyan-500 font-bold mr-3"
                    onClick={() => window.location.href = '/login'}
                >
                    Login
                </button>
                <button
                    type="button"
                    className="text-purple-500 hover:text-cyan-500 font-bold mr-3"
                    onClick={() => window.location.href = '/signup'}
                >
                    Register
                </button>
            </div>



            {/* <a href="https://github.com" className=" text-gray-800 dark:text-white mr-2">
                <i className="pi pi-github"></i>
            </a> */}

            <ThemeSwitcher />
        </div>
    );

    return (
        <header className="sticky top-0 z-50 w-full bg-white dark:bg-slate-950 text-slate-900 dark:text-white px-6 py-4 md:px-8 md:py-6 lg:px-12 lg:py-8">
            <div className="flex items-center justify-between">
                <div className="flex items-center">{start}</div>
                <div className="hidden lg:flex flex-grow justify-end">
                    <nav className="mt-2">
                        {items.map((item, index) => (
                            <a
                                key={index}
                                href={item.url}
                                className="px-4 py-2 bg-clip-text text-slate-900 dark:text-white hover:text-purple-700"
                            >
                                {item.label}
                            </a>
                        ))}
                    </nav>
                </div>

                <div className="flex items-center gap-2">
                    <div className="lg:hidden">
                        <Button
                            icon={<i className="pi pi-bars text-gray-500 dark:text-white"></i>}
                            onClick={() => setVisible(true)}
                            className="bg-transparent border-0 hover:bg-transparent bg-white dark:bg-slate-950"
                        />
                    </div>
                    {end}
                </div>
            </div>

            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                baseZIndex={1000}
                className='dark:bg-slate-950 border-none shadow-lg rounded-lg p-4 transition-transform duration-300 ease-in-out'
            >
                <PanelMenu model={items} className="w-full md:w-80 lg:w-96" />
                <div className="p-4">
                    <div className="header-logo mb-4"></div>

                    {/* Login and Register Buttons */}
                    <div className="mt-10 flex flex-col items-left space-y-2">
                        <button
                            type="button"
                            className={`${buttonStyle}  flex items-center space-x-2 transition-colors duration-200 hover:bg-sky-600 hover:text-purple-400`}
                            onClick={() => window.location.href = '/login'}
                        >
                            <i className="pi pi-sign-in"></i>
                            <span>Login</span>
                        </button>
                        <button
                            type="button"
                            className={`${buttonStyle} flex items-center space-x-2 transition-colors duration-200 hover:bg-sky-600 hover:text-purple-400`}
                            onClick={() => window.location.href = '/signup'}
                        >
                            <i className="pi pi-user-plus"></i>
                            <span>Register</span>
                        </button>
                    </div>

                    {/* Contact Us Section */}
                    <div className="flex flex-col items-start mt-6"> {/* Align items to the start (left) */}
                        <h4 className={`${buttonStyle} text-2xl text-purple-500 flex items-center transition-colors duration-200 hover:bg-purple-600 hover:text-white mb-4 hover:text-purple-400`}>
                            <i className="pi pi-envelope text-1xl mr-2 text-purple-500 "></i> {/* Icon size adjusted */}
                            Contact Us
                        </h4>

                        <p className="mt-2 text-purple-400 text-lg  dark:bg-slate-950 bg-white text-purple-800 font-semibold"> {/* Changed text color to purple */}
                            <span className="block text-left">Address: Bangalore, India</span>
                        </p>
                        <p className="mt-2 text-purple-400 dark:bg-slate-950 text-lg text-left bg-white text-purple-800 font-semibold"> {/* Changed text color to purple */}
                            Email: <a href="mailto:support@collegemate.io" className="text-purple-400 dark:bg-slate-950 hover:underline bg-white text-purple-800 font-semibold">support@collegemate.io</a>
                        </p>
                        <p className="mt-2 text-purple-400 dark:bg-slate-950 text-lg text-left bg-white text-purple-800 font-semibold"> {/* Changed text color to purple */}
                            Phone: <span className="text-purple-400 dark:bg-slate-950 bg-white text-purple-800 font-semibold">+91-8105827558</span>
                        </p>

                        {/* Social Media Links */}
                        <div className="mt-4 flex justify-start space-x-5"> {/* Center social media icons */}
                            <a href="https://twitter.com" className="text-white hover:text-purple-600 transition-colors duration-200" aria-label="Twitter">
                                <i className="pi pi-twitter text-2xl"></i>
                            </a>
                            <a href="https://linkedin.com" className="text-white hover:text-purple-600 transition-colors duration-200" aria-label="LinkedIn">
                                <i className="pi pi-linkedin text-2xl"></i>
                            </a>
                            <a href="https://facebook.com" className="text-white hover:text-purple-600 transition-colors duration-200" aria-label="Facebook">
                                <i className="pi pi-facebook text-2xl"></i>
                            </a>
                            <a href="https://wa.me/8105827558" className="text-white hover:text-green-500 transition-colors duration-200" aria-label="WhatsApp">
                                <i className="pi pi-whatsapp text-2xl"></i>
                            </a>

                        </div>
                    </div>
                </div>
            </Sidebar>

        </header>
    );
};

export default Header;
