import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Homepage'; // Public HomePage
import Header from './components/Header';
import Footer from './components/Footer';
import Register from './pages/RegisterPage'; // Register page component
import Login from './pages/LoginPage'; // Login page component
import NotFound from './pages/404'; // 404 Page
import Dashboard from './components/Dashboard';
import { AppProvider } from './components/Context'; // Ensure this path is correct
import Courses1 from './components/Courses1';
import JobDashbaord from './components/JobDashbaord';
import Messageingdashboard from './components/Messageingdashboard';
import Lms from './components/Lms';
import Vedio from './pages/Vedio';
import Vedio1 from './components/Vedio1';
import Profile from './components/Profile';
import Admin from './components/Admin';
import CompanyProfile from './components/CompanyProfile';
import Mentor from './components/Mentor';

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">

        {/* Routes for Public Pages (with Header and Footer) */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <HomePage />
                <Footer />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <Header />
                <Register />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Header />
                <Login />
                <Footer />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <Header />
                <NotFound />
                <Footer />
              </>
            }
          />

          {/* Place the AppProvider around the Dashboard route */}
          <Route
            path="/dashboard"
            element={
              <AppProvider>
                <Dashboard />
              </AppProvider>
            }
          />
          <Route
            path="/courses"
            element={

              <Courses1 />

            }
          />
          <Route
            path="/career"
            element={

              <JobDashbaord />

            }
          />
          <Route
            path="/messages"
            element={

              <Messageingdashboard />

            }
          />
          <Route
            path="/lms"
            element={

              <Lms />

            }
          />
          <Route
            path="/videos"
            element={

              <Vedio1 />

            }
          />
          <Route
            path="/profile"
            element={

              <Profile />

            }
          />
          <Route
            path="/admin-dashboard"
            element={

              <Admin />

            }
          />
          <Route
            path="/company-profile"
            element={

              <CompanyProfile />

            }
          />
          <Route
            path="/mentorship"
            element={

              <Mentor />

            }
          />
        </Routes>

      </div>
    </Router>
  );
}

export default App;
