// import React, { useState } from 'react';
// import { ChevronDown, Share, MoreVertical, X, Star, Clock, Globe, ChevronRight } from 'lucide-react';

// import { FaClock, FaEye, FaGlobe, FaHeading, FaStar, FaVideo } from 'react-icons/fa';
// import { MdFeedback, MdStart } from 'react-icons/md';
// // import { Star, Clock, Globe } from 'lucide-react';

// const StarRating = ({ rating }) => {
//     return (
//         <div className="flex gap-1">
//             {[1, 2, 3, 4, 5].map((star) => (
//                 <Star
//                     key={star}
//                     size={16}
//                     className={`${star <= rating ? 'fill-yellow-400 text-yellow-400' : 'fill-gray-200 text-gray-200'
//                         }`}
//                 />
//             ))}
//         </div>
//     );
// };

// const Vedio = () => {

//     const courseData = {
//         title: "The Complete 2024 Web Development Bootcamp",
//         rating: 4.7,
//         students: "1,359,097",
//         hours: "61.5",
//         lastUpdated: "August 2024",
//         languages: ["English", "English, Arabic (ﺎﻟ)"],
//         sections: [
//             {
//                 title: "Section 1: Front-End Web Development",
//                 duration: "37min",
//                 completed: "8/9",
//                 lectures: [
//                     {
//                         title: "1. What You'll Get in This Course",
//                         duration: "3min",
//                         completed: true,
//                         hasResources: true
//                     },
//                     {
//                         title: "2. Download the Course Syllabus",
//                         duration: "1min",
//                         completed: true,
//                         hasResources: true
//                     },
//                     {
//                         title: "3. Download the 12 Rules to Learn to Code eBook [Latest Edition]",
//                         duration: "1min",
//                         completed: true,
//                         hasResources: true
//                     },
//                     {
//                         title: "4. Download the Required Software",
//                         duration: "1min",
//                         completed: false,
//                         hasResources: false
//                     },
//                     {
//                         title: "5. How Does the Internet Actually Work?",
//                         duration: "5min",
//                         completed: true,
//                         hasResources: true
//                     },
//                     {
//                         title: "6. How Do Websites Actually Work?",
//                         duration: "8min",
//                         completed: true,
//                         hasResources: false
//                     },
//                     {
//                         title: "7. How to Get the Most Out of the Course",
//                         duration: "10min",
//                         completed: true,
//                         hasResources: true
//                     },
//                     {
//                         title: "8. How to Get Help When You're Stuck",
//                         duration: "7min",
//                         completed: true,
//                         hasResources: false
//                     },
//                     {
//                         title: "9. Pathfinder",
//                         duration: "2min",
//                         completed: true,
//                         hasResources: false
//                     }
//                 ]
//             }
//         ]
//     }; const [activeIndex, setActiveIndex] = useState(0);
//     const modules = [
//         {
//             id: 1,
//             title: 'Backend Capstone Project',
//             video: 'Capstone Overview',
//             timing: '12:30',
//             videoTitle: 'Getting Started with Backend',
//             views: '1.2K',
//             videoUrl: "https://videos.pexels.com/video-files/3973313/3973313-sd_640_360_25fps.mp4"
//         },
//         {
//             id: 2,
//             title: 'Data Engineering',
//             video: 'Data Pipelines',
//             timing: '9:45',
//             videoTitle: 'Introduction to Data Engineering',
//             views: '980',
//             videoUrl: "https://videos.pexels.com/video-files/1350205/1350205-sd_640_360_30fps.mp4", // Replace with actual video ID
//         },
//         {
//             id: 3,
//             title: 'DSA for Competitive Programming',
//             video: 'Algorithms 101',
//             timing: '8:20',
//             videoTitle: 'Efficient Sorting Techniques',
//             views: '2.3K',
//             videoUrl: "https://videos.pexels.com/video-files/2278095/2278095-sd_640_360_30fps.mp4", // Replace with actual video ID
//         },
//         {
//             id: 4,
//             title: 'Product Management for Software Engineers',
//             video: 'PM Basics',
//             timing: '15:00',
//             videoTitle: 'Product Lifecycle Overview',
//             views: '1.5K',
//             videoUrl: "https://videos.pexels.com/video-files/3163534/3163534-sd_640_360_30fps.mp4", // Replace with actual video ID
//         },
//         {
//             id: 5,
//             title: 'Machine Learning Basics',
//             video: 'ML Foundations',
//             timing: '14:35',
//             videoTitle: 'Intro to Machine Learning',
//             views: '1.8K',
//             videoUrl: "https://videos.pexels.com/video-files/3973313/3973313-sd_640_360_25fps.mp4", // Replace with actual video ID
//         }
//     ];

//     return (
//         <>
//             <nav class="flex mt-5 mb-5" aria-label="Breadcrumb">
//                 <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
//                     <li class="inline-flex items-center">
//                         <a href="/dashboard" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-purple-600 dark:text-purple-600 dark:hover:text-white">
//                             <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
//                                 <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
//                             </svg>
//                             Home
//                         </a>
//                     </li>

//                     <li aria-current="page">
//                         <div class="flex items-center">
//                             <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
//                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
//                             </svg>
//                             <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Vedio</span>
//                         </div>
//                     </li>
//                 </ol>
//             </nav>

//             <div className="min-h-screen bg-white dark:bg-slate-900">
//                 {/* Header */}
//                 <div className="bg-slate-200 text-slate-900 dark:bg-slate-900 dark:text-white px-4 md:px-6 py-4 flex justify-between items-center shadow-md">
//                     <h1 className="text-base md:text-xl font-semibold">{courseData.title}</h1>
//                     <div className="flex items-center space-x-2 md:space-x-4">
//                         <button className="flex items-center text-xs md:text-sm hover:text-gray-300">
//                             <Star className="w-3 h-3 md:w-4 md:h-4 mr-1" /> Feedback
//                         </button>
//                         <button className="text-xs md:text-sm hover:text-gray-300">Get certificate</button>
//                         <button className="flex items-center text-xs md:text-sm hover:text-gray-300">
//                             <Share className="w-3 h-3 md:w-4 md:h-4 mr-1" /> Share
//                         </button>
//                         <MoreVertical className="w-3 h-3 md:w-4 md:h-4" />
//                     </div>
//                 </div>

//                 {/* Main Content */}
//                 <div className="flex flex-col md:flex-row">
//                     {/* Sidebar - Course Content */}
//                     <div className="w-full md:w-1/4 lg:w-1/5  overflow-y-auto dark:border-gray-700 bg-white dark:bg-slate-900">
//                         <div className="p-4 border-b border-gray-300 dark:border-gray-700 flex justify-between items-center">
//                             <h2 className="font-semibold text-gray-800 dark:text-white">Course content</h2>
//                             <X className="w-4 h-4 text-gray-500" />
//                         </div>

//                         {courseData.sections.map((section, index) => (
//                             <div key={index} className="border-b border-gray-300 dark:border-gray-700">
//                                 <div className="p-4 flex justify-between items-center bg-gray-50 dark:bg-gray-800">
//                                     <h3 className="font-medium text-gray-900 dark:text-white">{section.title}</h3>
//                                     <ChevronDown className="w-4 h-4 text-gray-500" />
//                                 </div>
//                                 <div>
//                                     {section.lectures.map((lecture, lectureIndex) => (
//                                         <div key={lectureIndex} className={`p-4 flex justify-between items-center ${lecture.completed ? 'bg-gray-100 dark:bg-slate-800' : ''}`}>
//                                             <div className="flex items-center space-x-2">
//                                                 <input type="checkbox" checked={lecture.completed} className="w-4 h-4 text-purple-600 rounded" readOnly />
//                                                 <span className="text-sm text-gray-800 dark:text-gray-200">{lecture.title}</span>
//                                             </div>
//                                             <div className="text-xs text-gray-500 dark:text-gray-400">{lecture.duration}</div>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                         ))}
//                     </div>

//                     {/* Video Area */}
//                     <div className="flex-1 p-4">
//                         <div className="bg-white dark:bg-slate-900 shadow-md rounded-lg overflow-hidden">
//                             <video className="h-48 md:h-96 w-full rounded-md" controls>
//                                 <source src="https://videos.pexels.com/video-files/2278095/2278095-sd_640_360_30fps.mp4" type="video/mp4" />
//                                 Your browser does not support the video tag.
//                             </video>
//                         </div>

//                         <div className="max-w-6xl mx-auto p-4 md:p-6">
//                             {/* Tab Navigation */}
//                             <div className="flex flex-wrap space-x-2 md:space-x-8 overflow-x-auto">
//                                 {['Overview', 'Q&A', 'Notes', 'Announcements', 'Reviews'].map((tab, i) => (
//                                     <button key={i} className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white">
//                                         {tab}
//                                     </button>
//                                 ))}
//                             </div>

//                             <div className="py-4">
//                                 <h1 className="text-lg md:text-xl font-bold text-gray-800 dark:text-white">
//                                     Improve your spoken English in just 2 weeks!
//                                 </h1>

//                                 {/* Stats Section */}
//                                 <div className="flex flex-wrap gap-6 my-4">
//                                     <div className="flex items-center gap-2">
//                                         <FaStar className="text-yellow-400" />
//                                         <span className="text-gray-600 dark:text-gray-300">4.8 (9,403 ratings)</span>
//                                     </div>
//                                     <div className="flex items-center gap-6">
//                                         <div className="text-gray-600 dark:text-gray-300">
//                                             <span className="font-bold text-gray-800 dark:text-white">218,627</span> Students
//                                         </div>
//                                         <div className="text-gray-600 dark:text-gray-300">
//                                             <span className="font-bold text-gray-800 dark:text-white">3 hours</span> Total
//                                         </div>
//                                     </div>
//                                 </div>

//                                 {/* Last Updated & Language */}
//                                 <div className="flex flex-wrap gap-4 my-4 text-sm text-gray-600 dark:text-gray-300">
//                                     <div className="flex items-center gap-2">
//                                         <FaClock />
//                                         Last updated June 2018
//                                     </div>
//                                     <div className="flex items-center gap-2">
//                                         <FaGlobe />
//                                         <span>English</span>
//                                         <span className="text-gray-400">English [Auto]</span>
//                                     </div>
//                                 </div>

//                                 {/* Schedule Learning Section */}
//                                 <div className="bg-gray-50 dark:bg-slate-800 p-4 md:p-6 rounded-lg my-4">
//                                     <div className="flex items-center gap-2 md:gap-4 mb-4">
//                                         <FaClock size={20} className="text-gray-600 dark:text-gray-300" />
//                                         <h2 className="text-lg md:text-xl font-semibold text-gray-800 dark:text-white">
//                                             Schedule learning time
//                                         </h2>
//                                     </div>
//                                     <p className="text-gray-600 dark:text-gray-300 mb-4 text-md">
//                                         Learning a little each day adds up. Set time aside to learn and get reminders using your learning scheduler.
//                                     </p>
//                                     <div className="flex gap-4">
//                                         <button className="px-3 md:px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700">Get started</button>
//                                         <button className="px-3 md:px-4 py-2 bg-gray-200 dark:bg-slate-700 text-gray-800 dark:text-gray-300 rounded-md hover:bg-gray-300 dark:hover:bg-slate-600">Dismiss</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     {/* Modules Sidebar */}
//                     <div className="hidden md:block w-full md:w-1/4 lg:w-1/5 bg-white dark:bg-slate-900 border-l border-gray-300 dark:border-gray-700 shadow-md">
//                         <h2 className="text-lg font-semibold p-4 border-b border-gray-300 dark:border-gray-700">Modules</h2>
//                         {modules.map((module) => (
//                             <div key={module.id} className="p-4 mb-4 bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 hover:bg-gray-200 rounded-lg">
//                                 <h3 className="font-medium text-gray-900 dark:text-gray-100">MODULE - {module.id}</h3>
//                                 <div className="text-gray-500 dark:text-gray-400 text-sm mb-2">{module.title}</div>
//                                 <div className="flex items-center">
//                                     <FaClock className="text-gray-900 dark:text-white mr-2" />
//                                     <span className="font-semibold text-gray-900 dark:text-white">{module.timing}</span>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Vedio;


import React from 'react';
import { ChevronDown, ChevronUp, Play, ChevronLeft, Star } from 'lucide-react';
import { FaClock } from 'react-icons/fa';

const Vedio = () => {
    return (
        <>
            <div className="mb-6">

                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>Company Profile</span>
                </div>
            </div>

            <div className="min-h-screen bg-gray-100 dark:bg-slate-950 text-slate-900 dark:text-white p-4">
                {/* Header */}
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <button className="flex items-center text-gray-600">
                            <ChevronLeft className="w-5 h-5" />
                        </button>
                        <div>
                            <h1 className="text-xl font-semibold text-left">Web Development </h1>
                            <div className="flex items-center gap-2 text-sm text-gray-600">
                                <span>38 lessons</span>
                                <span>•</span>
                                <span>4h 30min</span>
                                <span>•</span>
                                <span>4.5 (126 reviews)</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <button className="text-purple-600">Share</button>
                        <button className=" bg-purple-600 border border-purple-600 px-4 py-2 rounded-lg flex items-center space-x-2">
                            <svg
                                className="w-4 h-4 fill-current text-yellow-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                            </svg>
                            <span className="text-white">Give Feedback</span>
                        </button>

                    </div>
                </div>

                {/* Main Content */}
                <div className="grid grid-cols-3 gap-8">
                    {/* Video Preview */}
                    <div className="col-span-2">
                        <div className="relative rounded-2xl overflow-hidden">
                            <video
                                className="w-full object-cover"
                                src="https://videos.pexels.com/video-files/3973313/3973313-sd_640_360_25fps.mp4"
                                controls
                                autoPlay
                                loop
                                muted
                            >
                                Your browser does not support the video tag.
                            </video>

                            <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white/90 p-4 rounded-full">
                                <Play className="w-6 h-6 text-purple-600" />
                            </button>
                        </div>

                        {/* Navigation Pills */}
                        <div className="flex gap-6 border-b mt-6">
                            <button className="text-purple-600 border-b-2 border-purple-600 pb-2">Overview</button>
                            <button className="text-gray-600 pb-2">Author</button>
                            <button className="text-gray-600 pb-2">FAQ</button>
                            <button className="text-gray-600 pb-2">Announcements</button>
                            <button className="text-gray-600 pb-2">Reviews</button>
                        </div>

                        {/* Course Description */}
                        <div className="mt-6">
                            <h2 className="text-xl font-semibold mb-4 text-left">About Course</h2>
                            <p className="text-gray-600 mb-4 text-left">
                                Unlock the power of Figma, the leading collaborative design tool, with our comprehensive online course.
                                Whether you're a novice or looking to enhance your skills, this course will guide you through Figma's robust
                                features and workflows.
                            </p>
                            <p className="text-gray-600 mb-6 text-left">
                                Perfect for UI/UX designers, product managers, and anyone interested in modern design tools. Join us to elevate
                                your design skills and boost your productivity with Figma!
                            </p>

                            <h2 className="text-xl font-semibold mb-4 text-left">What You'll Learn</h2>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex items-center gap-2 text-gray-600">
                                    <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Setting up the environment
                                </div>
                                <div className="flex items-center gap-2 text-gray-600">
                                    <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Understand HTML Programming
                                </div>
                                <div className="flex items-center gap-2 text-gray-600">
                                    <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Advanced HTML Practices
                                </div>
                                <div className="flex items-center gap-2 text-gray-600">
                                    <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Build a portfolio website
                                </div>
                            </div>
                        </div>

                        <h2 className="text-xl font-semibold mb-4 text-left mt-6">Course Videos</h2>
                        <div className="grid grid-cols-3 gap-4">
                            <div className=" dark:bg-gray-900 bg-gray-200 rounded-lg p-4">
                                <h3 className="font-medium text-left"> Introduction</h3>
                                <video className="w-full h-auto" controls>
                                    <source src="https://videos.pexels.com/video-files/3973313/3973313-sd_640_360_25fps.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="flex items-center mt-2 text-gray-600">

                                    <FaClock className="w-4 h-4 text-gray-400 mr-1" />
                                    <p className="text-sm">16 min</p>
                                </div>
                                <div className="flex items-center mt-2 text-gray-600">

                                    <div className="flex items-center text-yellow-500">
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                    </div>
                                    <p className="ml-2 text-sm">(4.0)</p>
                                </div>
                            </div>
                            <div className=" dark:bg-gray-900 bg-gray-200 rounded-lg p-4">
                                <h3 className="font-medium text-left"> JavaScript Introduction
                                </h3>
                                <video className="w-full h-auto" controls>
                                    <source src="https://videos.pexels.com/video-files/3973313/3973313-sd_640_360_25fps.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="flex items-center mt-2 text-gray-600">

                                    <FaClock className="w-4 h-4 text-gray-400 mr-1" />
                                    <p className="text-sm">10 min</p>
                                </div>
                                <div className="flex items-center mt-2 text-gray-600">

                                    <div className="flex items-center text-yellow-500">
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                    </div>
                                    <p className="ml-2 text-sm">(4.0)</p>
                                </div>
                            </div>

                            <div className=" dark:bg-gray-900 bg-gray-200 rounded-lg p-4">
                                <h3 className="font-medium text-left"> Advanced HTML & CSS</h3>
                                <video className="w-full h-auto" controls>
                                    <source src="https://videos.pexels.com/video-files/3973313/3973313-sd_640_360_25fps.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="flex items-center mt-2 text-gray-600">

                                    <FaClock className="w-4 h-4 text-gray-400 mr-1" />
                                    <p className="text-sm">19 min</p>
                                </div>
                                <div className="flex items-center mt-2 text-gray-600">

                                    <div className="flex items-center text-yellow-500">
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                        <svg className="w-4 h-4 text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.73L6.82 21z" />
                                        </svg>
                                    </div>
                                    <p className="ml-2 text-sm">(4.0)</p>
                                </div>
                            </div>
                        </div>



                    </div>

                    {/* Course Content Sidebar */}
                    <div className="col-span-1 mb-6 ">
                        <div className="bg-white rounded-xl p-6 shadow-sm bg-gray-100 dark:bg-slate-950 text-slate-900 dark:text-white">
                            <h2 className="font-semibold mb-4 text-2xl text-purple-600">Course content</h2>

                            {/* Chapter Accordions */}
                            <div className="space-y-2">
                                <div className="border rounded-lg">
                                    <div className="flex items-center justify-between p-4 cursor-pointer">
                                        <div>
                                            <h3 className="font-medium">01: Intro</h3>
                                            <p className="text-sm text-gray-500">22min</p>
                                        </div>
                                        <ChevronUp className="w-5 h-5 text-gray-400" />
                                    </div>
                                    <div className="px-4 pb-4">
                                        <div className="flex items-center justify-between py-2">
                                            <div className="flex items-center gap-2">
                                                <Play className="w-4 h-4 text-gray-400" />
                                                <span className="text-sm text-gray-400"> Introduction </span>
                                            </div>
                                            <span className="text-sm text-gray-500">2 min</span>
                                        </div>
                                        <div className="flex items-center justify-between py-2">
                                            <div className="flex items-center gap-2">
                                                <Play className="w-4 h-4 text-gray-400" />
                                                <span className="text-sm text-gray-400">Web Development Basics </span>
                                            </div>
                                            <span className="text-sm text-gray-500">5 min</span>
                                        </div>
                                        <div className="flex items-center justify-between py-2">
                                            <div className="flex items-center gap-2">
                                                <Play className="w-4 h-4 text-gray-400" />
                                                <span className="text-sm text-gray-400">HTML & Structure</span>
                                            </div>
                                            <span className="text-sm text-gray-500">12 min</span>
                                        </div>
                                        <div className="flex items-center justify-between py-2">
                                            <div className="flex items-center gap-2">
                                                <Play className="w-4 h-4 text-gray-400" />
                                                <span className="text-sm text-gray-400">CSS & Styling Basics</span>
                                            </div>
                                            <span className="text-sm text-gray-500 text-left">3 min</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Other chapters (collapsed) */}
                                <div className="border rounded-lg p-4">
                                    <div className="flex items-center justify-between cursor-pointer">
                                        <div>
                                            <h3 className="font-medium">02: JavaScript Introduction</h3>
                                            <p className="text-sm text-gray-500 text-left">1h 20min</p>
                                        </div>
                                        <ChevronDown className="w-5 h-5 text-gray-400" />
                                    </div>
                                </div>

                                <div className="border rounded-lg p-4">
                                    <div className="flex items-center justify-between cursor-pointer">
                                        <div>
                                            <h3 className="font-medium">03: Advanced HTML & CSS</h3>
                                            <p className="text-sm text-gray-500 text-left">36min</p>
                                        </div>
                                        <ChevronDown className="w-5 h-5 text-gray-400" />
                                    </div>
                                </div>
                                <div className="border rounded-lg p-4">
                                    <div className="flex items-center justify-between cursor-pointer">
                                        <div>
                                            <h3 className="font-medium">04: Responsive Web Design</h3>
                                            <p className="text-sm text-gray-500 text-left">36min</p>
                                        </div>
                                        <ChevronDown className="w-5 h-5 text-gray-400" />
                                    </div>
                                </div>
                                <div className="border rounded-lg p-4">
                                    <div className="flex items-center justify-between cursor-pointer">
                                        <div>
                                            <h3 className="font-medium">05: CSS Flexbox & Grid</h3>
                                            <p className="text-sm text-gray-500 text-left">36min</p>
                                        </div>
                                        <ChevronDown className="w-5 h-5 text-gray-400" />
                                    </div>
                                </div>

                                {/* Author Section */}
                                <div className="mt-8">
                                    <h2 className="font-semibold mb-4 text-2xl text-purple-600">Authors</h2>

                                    {/* Author 1 */}
                                    <div className="flex items-center gap-4 p-4 bg-white border rounded-lg dark:bg-slate-900 mb-4">
                                        <img
                                            src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=400"
                                            alt="Author"
                                            className="w-12 h-12 rounded-full"
                                        />
                                        <div>
                                            <h3 className="font-medium text-gray-800 dark:text-white">Crystal Lucas</h3>
                                            <p className="text-sm text-gray-600 dark:text-gray-300">UI/UX Specialist</p>
                                            <div className="flex items-center gap-1">
                                                {[...Array(5)].map((_, index) => (
                                                    <Star key={index} className="w-4 h-4 fill-current text-yellow-400" />
                                                ))}
                                                <span className="text-sm text-gray-600 dark:text-gray-300">(4.8)</span>
                                            </div>
                                            <p className="text-xs text-gray-500 mt-1 dark:text-gray-400">Passionate about creating user-friendly interfaces that prioritize accessibility and design.</p>
                                        </div>
                                    </div>

                                    {/* Author 2 */}
                                    <div className="flex items-center gap-4 p-4 bg-white border rounded-lg dark:bg-slate-900 mb-4">
                                        <img
                                            src="https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&w=400"
                                            alt="Author"
                                            className="w-12 h-12 rounded-full"
                                        />
                                        <div>
                                            <h3 className="font-medium text-gray-800 dark:text-white">David Johnson</h3>
                                            <p className="text-sm text-gray-600 dark:text-gray-300">Software Engineer</p>
                                            <div className="flex items-center gap-1">
                                                {[...Array(5)].map((_, index) => (
                                                    <Star key={index} className="w-4 h-4 fill-current text-yellow-400" />
                                                ))}
                                                <span className="text-sm text-gray-600 dark:text-gray-300">(4.7)</span>
                                            </div>
                                            <p className="text-xs text-gray-500 mt-1 dark:text-gray-400">Experienced in building scalable web applications with a focus on performance and security.</p>
                                        </div>
                                    </div>

                                    {/* Author 3 */}
                                    <div className="flex items-center gap-4 p-4 bg-white border rounded-lg dark:bg-slate-900">
                                        <img
                                            src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=400"
                                            alt="Author"
                                            className="w-12 h-12 rounded-full"
                                        />
                                        <div>
                                            <h3 className="font-medium text-gray-800 dark:text-white">Maya Smith</h3>
                                            <p className="text-sm text-gray-600 dark:text-gray-300">Data Analyst</p>
                                            <div className="flex items-center gap-1">
                                                {[...Array(5)].map((_, index) => (
                                                    <Star key={index} className="w-4 h-4 fill-current text-yellow-400" />
                                                ))}
                                                <span className="text-sm text-gray-600 dark:text-gray-300">(4.9)</span>
                                            </div>
                                            <p className="text-xs text-gray-500 mt-1 dark:text-gray-400">Specializes in extracting insights from data to drive business decisions and strategy.</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Vedio;