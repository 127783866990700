import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Link } from 'react-router-dom';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [collegeId, setCollegeId] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [collegePhoto, setCollegePhoto] = useState(null); // For storing the uploaded photo

    const [agreed, setAgreed] = useState(false);

    const handleSignup = () => {
        // Implement signup logic here
        console.log('Signing up with:', { email, password, confirmPassword });
    };

    return (
        <div className="flex overflow-hidden flex-col md:flex-row items-center justify-center dark:bg-slate-950">

            <div className="flex flex-col justify-center p-9 w-full md:w-1/2 lg:w-1/3 bg-white dark:bg-slate-950 ">
                <div className="flex items-center justify-center mb-6">

                </div>
                <h2 className="mb-5 text-2xl sm:text-3xl md:text-4xl font-bold uppercase tracking-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-700 to-cyan-500 text-center">
                    Create your free account
                </h2>
                <p className="mb-4 text-1xl text-gray-700 dark:text-gray-300 text-center">
                    Join our collegemate community to connect, share experiences, and access exclusive resources!
                </p>


                <form className="space-y-6">
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-user text-gray-400"></i>
                            </div>
                            <InputText
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter your username"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mb-6">
                        <div className="space-y-5">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <i className="pi pi-id-card text-gray-400"></i>
                                </div>
                                <InputText
                                    id="collegeId"
                                    value={collegeId}
                                    onChange={(e) => setCollegeId(e.target.value)}
                                    placeholder="Enter your college ID"
                                    className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                    required
                                />
                            </div>
                        </div>
                        <div className="space-y-5">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <i className="pi pi-envelope text-gray-400"></i>
                                </div>
                                <InputText
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                    required
                                />
                            </div>
                        </div>
                        <div className="space-y-5">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <i className="pi pi-lock text-gray-400"></i>
                                </div>
                                <InputText
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    placeholder="Enter your password"
                                    className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                    required
                                />
                            </div>
                        </div>
                        <div className="space-y-5">
                            <div className="relative ">


                                <InputText
                                    id="collegePhoto"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => setCollegePhoto(e.target.files[0])} // Ensure you have state for the college photo
                                    className="hidden" // Hide the default file input
                                    required
                                />
                                <button
                                    type="button"
                                    className=" mt-1 flex bg-white text-gray-300 dark:bg-gray-900 items-center justify-between w-full pl-10 pr-4 py-2 text-gray-600  border border-purple-300 rounded-lg bg-gray-900 transition-all duration-300 ease-in-out focus:ring-2 focus:ring-purple-600 focus:outline-sky-600"
                                    onClick={() => document.getElementById('collegePhoto').click()} // Trigger file input on button click
                                >
                                    <span className="py-1 mr-1 text-sm  text-gray-500 font-semibold">Upload College ID</span>

                                </button>
                                <span className=" text-purple-500 text-sm ">
                                    {collegePhoto ? collegePhoto.name : 'No file chosen'} {/* Display selected file name */}
                                </span>
                            </div>
                        </div>





                    </div>
                    <div className="flex items-start space-x-2 mb-6">
                        <Checkbox
                            checked={agreed}
                            onChange={(e) => setAgreed(e.checked)}
                            id="termsAndConditions"
                        />
                        <label htmlFor="termsAndConditions" className="text-sm md:text-base text-gray-600 dark:text-gray-300">
                            I agree to the <a href="/terms" className="text-purple-400">Terms and Conditions</a> and <a href="/privacy" className="text-purple-400">Privacy Policy</a>
                        </label>
                    </div>


                    <div className="relative inline-flex items-center justify-center gap-3 group mt-10 w-full max-w-md">
                        <div className="absolute inset-0 duration-1000 opacity-60 transition-all bg-gradient-to-r from-indigo-500 via-pink-500 to-yellow-400 rounded-full blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>
                        <a href="#"
                            className="group relative flex items-center justify-center text-lg rounded-full bg-slate-950 px-8 py-2 font-semibold text-white transition-all duration-200 hover:bg-slate-950 hover:shadow-lg hover:-translate-y-0.5 hover:shadow-gray-600/30 w-full">
                            Sign Up
                        </a>
                    </div>
                </form>


                <p className="mt-4 text-gray-600 dark:text-gray-300 text-center text-sm md:text-base lg:text-lg">
                    Already have an account?
                    <Link to="/login" className="text-purple-400 text-sm md:text-base lg:text-lg font-semibold">
                        Sign in
                    </Link>
                </p>

                <div className="flex items-center my-4">
                    <span className="flex-grow border-t border-gray-300"></span>
                    <span className="px-4 bg-transparent text-gray-400">Or sign In with</span>
                    <span className="flex-grow border-t border-gray-300"></span>
                </div>


                <div className="flex flex-col items-center mb-6">
                    <button
                        className="w-full max-w-xs font-bold rounded-full py-3 text-slate-800 dark:text-gray-200 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline bg-white dark:bg-slate-950">
                        <div className="bg-white dark:bg-slate-100 p-1 rounded-full">
                            <svg className="w-4" viewBox="0 0 533.5 544.3">
                                <path
                                    d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                                    fill="#4285f4" />
                                <path
                                    d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                                    fill="#34a853" />
                                <path
                                    d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                                    fill="#fbbc04" />
                                <path
                                    d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                                    fill="#ea4335" />
                            </svg>
                        </div>
                        <span className="ml-3 text-black dark:text-gray-200">
                            Sign In with Google
                        </span>
                    </button>
                </div>
            </div>


        </div>
    );
};

export default Signup;
