import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Avatar } from 'primereact/avatar';
import { SmilePlus } from 'lucide-react';

const Courses = () => {
    const courses = [
        {
            id: 1,
            title: 'React Development by Upskill',
            image: 'https://images.pexels.com/photos/1181675/pexels-photo-1181675.jpeg',
            rating: 4.5,
            enrolled: 120,
            cost: '$199',
            company: 'Scaler',
            hours: '30 Hours',
        },
        {
            id: 2,
            title: 'Cybersecurity Fundamentals by Udemy',
            image: 'https://images.pexels.com/photos/3949100/pexels-photo-3949100.jpeg?auto=compress&cs=tinysrgb&w=600',
            rating: 4.2,
            enrolled: 95,
            cost: '$149',
            company: 'Udemy',
            hours: '25 Hours',
        },
        {
            id: 3,
            title: 'Full Stack Web Dev Bootcamp',
            image: 'https://images.pexels.com/photos/3184294/pexels-photo-3184294.jpeg',
            rating: 4.8,
            enrolled: 200,
            cost: '$299',
            company: 'Coursera',
            hours: '40 Hours',
        },
        {
            id: 4,
            title: 'Blockchain and Cryptocurrency Essentials',
            image: 'https://images.pexels.com/photos/5980856/pexels-photo-5980856.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.6,
            enrolled: 150,
            cost: '$249',
            company: 'edX',
            hours: '35 Hours',
        },
        {
            id: 5,
            title: 'UI Vector Illustration Design',
            instructor: 'Savannah Nguyen',
            avatar: 'https://images.pexels.com/photos/1824273/pexels-photo-1824273.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/6289025/pexels-photo-6289025.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.5,
            enrolled: 120,
            cost: '$199',
            completed: 45,
            daysLeft: 3,
            duration: 30,
        },
        {
            id: 6,
            title: 'UI Styleguide with Figma',
            instructor: 'Orchestra',
            avatar: 'https://images.pexels.com/photos/3581695/pexels-photo-3581695.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/3471423/pexels-photo-3471423.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.8,
            enrolled: 124,
            cost: '$149',
            completed: 75,
            daysLeft: 5,
            duration: 40,
        },
        {
            id: 7,
            title: 'Marketing Analytics Essentials',
            instructor: 'Lara McKenzie',
            avatar: 'https://images.pexels.com/photos/1212984/pexels-photo-1212984.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/7109162/pexels-photo-7109162.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.7,
            enrolled: 230,
            cost: '$199',
            completed: 80,
            daysLeft: 2,
            duration: 30,
        },
        {
            id: 8,
            title: 'Creative Writing: Fiction Fundamentals',
            instructor: 'J.K. Simmons',
            avatar: 'https://images.pexels.com/photos/1319911/pexels-photo-1319911.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/210661/pexels-photo-210661.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.9,
            enrolled: 150,
            cost: '$99',
            completed: 60,
            daysLeft: 10,
            duration: 50,
        }

    ];

    return (
        <div className="container mx-auto p-6">
            <header className="flex justify-between items-center mb-6">
                <div className="mb-6">

                    <div className="flex items-center text-sm text-gray-500">
                        <span className="hover:text-blue-600 cursor-pointer">Home</span>
                        <span className="mx-2">›</span>
                        <span className=' text-purple-600'>Courses</span>
                    </div>
                </div>


                <div className="flex items-center space-x-4">
                    <Dropdown options={[{ label: 'All Courses', value: 'all' }, { label: 'New Courses', value: 'new' }, { label: 'Popular Courses', value: 'popular' }]} placeholder="Filter" className="w-40" />
                    {/* <input type="date" className="border rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-300" /> */}
                </div>
            </header>
            <div className="relative w-full h-38 bg-indigo-500 rounded-xl overflow-hidden shadow-lg">
                {/* Background waves */}
                <div className="absolute inset-0 opacity-40">
                    <svg className="w-full h-full" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0,1000 C300,800 400,600 1000,800 L1000,1000 Z" fill="#a7f3d0" />
                        <path d="M0,1000 C300,900 600,850 1000,900 L1000,1000 Z" fill="#67e8f9" />
                    </svg>
                </div>

                {/* Content */}
                <div className="relative z-10 p-6 flex flex-col h-full justify-between">
                    <div>
                        <h2 className="text-white text-4xl font-bold mb-2">
                            Upgrade Your Skills with Top Courses
                        </h2>
                        <p className="text-cyan-100 text-sm">
                            Learn from 300,000+ expert-led courses and boost your career with personalized learning.
                        </p>
                    </div>

                    <div className="flex justify-between items-end">
                        <button className="bg-indigo-800 text-cyan-100 px-4 py-2 rounded-lg text-sm font-semibold hover:bg-indigo-700 transition-colors">
                            Explore Courses
                        </button>
                        {/* <SmilePlus className="text-cyan-200 w-8 h-8" /> */}
                    </div>
                </div>
            </div>
            {/* All Courses */}
            <section>
                <h2 className="text-2xl font-semibold mt-6 mb-6 text-left text-indigo-600 dark:text-indigo-300">Trending Courses</h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {courses.slice(0, 4).map((course) => (
                        <div
                            key={course.id}
                            className="bg-indigo-100 dark:bg-gray-900 rounded-lg shadow-sm p-5 hover:shadow-lg"
                        >
                            <img
                                src={course.image}
                                alt={course.title}
                                className="w-full h-44 object-cover rounded-lg mb-4"
                            />
                            <h4 className="font-semibold text-lg text-gray-800 dark:text-gray-100 mb-1">{course.title}</h4>

                            {/* Ratings */}
                            <div className="flex items-center text-yellow-400 mb-2 justify-end">
                                {'★'.repeat(Math.floor(course.rating))}
                                {'☆'.repeat(5 - Math.floor(course.rating))}
                                <span className="text-gray-500 text-sm ml-2">({course.rating})</span>
                            </div>

                            {/* Course Cost and Company */}
                            <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400 mt-3">
                                <span>{course.cost}</span>

                                <span>
                                    <i className="pi pi-clock mr-2 text-purple-600 dark:text-indigo-600"></i>
                                    {course.hours}
                                </span>

                                <span className="font-bold text-white dark:text-white bg-indigo-600 dark:bg-indigo-600 px-2 py-1 rounded-lg">
                                    {course.company || 'Featured'}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </section>





            {/* Enrolled Courses */}
            <section className="mt-10">
                <h2 className="text-2xl font-semibold mt-6 mb-6 text-left text-indigo-600 dark:text-indigo-300">Enrolled Courses</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {courses.slice(4, 8).map((course) => (
                        <div key={course.id} className="bg-orange-100 dark:bg-gray-900 rounded-lg shadow-md p-4 hover:shadow-lg ">
                            <div className="relative h-40 bg-gray-200">
                                <img src={course.image} alt={course.title} className="w-full h-full object-cover rounded-lg" />
                                <div className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white text-xs font-semibold py-1 px-2 rounded">
                                    {course.title}
                                </div>
                            </div>

                            <div className="flex items-center gap-2 mt-4">
                                <img src={course.avatar} alt={course.instructor} className="w-10 h-10 rounded-full" />
                                <span className="text-sm font-medium">{course.instructor}</span>
                            </div>

                            <div className="flex items-center text-yellow-500 mt-2">
                                {'★'.repeat(Math.floor(course.rating))}
                                {'☆'.repeat(5 - Math.floor(course.rating))}
                                <span className="text-gray-500 text-sm ml-2">({course.rating})</span>
                            </div>

                            {/* <p className="text-sm text-gray-500 mt-1">{course.enrolled} Enrolled</p> */}

                            <div className="flex justify-between text-sm text-gray-700 mt-2">
                                <span>{course.cost}</span>
                                <span>{course.duration} days</span>
                            </div>

                            <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                                <div className="bg-indigo-600 dark:bg-indigo-600 h-2 rounded-full" style={{ width: `${course.completed}%` }}></div>
                            </div>

                            <div className="flex justify-between mt-1 text-xs text-gray-500">
                                <span>Completed: {course.completed}%</span>
                                <span>{course.daysLeft} days left</span>
                            </div>
                        </div>
                    ))}
                </div>
            </section>


            {/* New Courses */}
            <section className="mt-10">
                <h2 className="text-2xl font-semibold mt-6 mb-6 text-left text-indigo-600 dark:text-indigo-300">
                    New Courses
                </h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {courses.slice(0, 4).map((course) => (
                        <div key={course.id} className="bg-indigo-100 dark:bg-gray-900 rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-200">
                            <div className="relative">
                                <img
                                    src={course.image}
                                    alt={course.title}
                                    className="w-full h-40 object-cover rounded-lg mb-4"
                                />
                                <div className="absolute top-2 right-2 bg-indigo-600 text-white text-xs font-semibold py-1 px-2 rounded">
                                    New
                                </div>
                            </div>
                            <h4 className="font-semibold text-lg">{course.title}</h4>

                            <div className="flex items-center text-yellow-500 mb-1">
                                {'★'.repeat(Math.floor(course.rating))}
                                {'☆'.repeat(5 - Math.floor(course.rating))}
                                <span className="text-gray-500 text-sm ml-2">({course.rating})</span>
                            </div>

                            <div className="flex items-center text-gray-500 text-sm mb-2">
                                <i className="pi pi-users mr-2"></i>
                                <span>{course.enrolled} Students Enrolled</span>
                            </div>

                            <div className="flex items-center justify-between text-sm text-gray-700 mt-2">
                                <div className="flex items-center text-gray-500">
                                    <i className="pi pi-tag mr-2"></i>
                                    <span>{course.cost}</span>
                                </div>
                                <div className="flex items-center text-gray-500">
                                    <i className="pi pi-clock mr-2 text-purple-600 dark:text-indigo-300"></i>
                                    <span >{course.hours} Hours</span>
                                </div>
                            </div>

                            {/* Additional Features */}
                            <div className="mt-4">
                                <button className="w-full bg-indigo-600 text-white font-semibold py-2 rounded-md hover:bg-indigo-700 transition duration-200">
                                    Enroll Now
                                </button>
                                <button className="w-full bg-transparent border border-indigo-600 text-indigo-600 font-semibold py-2 rounded-md mt-2 hover:bg-indigo-600 hover:text-white transition duration-200">
                                    View Details
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>


        </div>
    );
};

export default Courses;
