// context.jsx
import React, { useState, useContext } from 'react';

// Create a context
const AppContext = React.createContext();

// Create a provider component
const AppProvider = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const openSidebar = () => {
        setIsSidebarOpen(true);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    return (
        <AppContext.Provider value={{ isSidebarOpen, openSidebar, closeSidebar }}>
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to use global context
export const useGlobalContext = () => {
    return useContext(AppContext);
};

// Export the provider
export { AppContext, AppProvider };
