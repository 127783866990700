// Data.jsx
import React from 'react';
import {

    FaUserFriends,

    FaCalendarAlt,
    FaEnvelope,
    FaBriefcase,
    FaBell,
    FaPhone,
    FaBehance,
    FaFacebook,
    FaLinkedin,
    FaTwitter,
    FaSketch,
    FaHome,

    FaFolderOpen,
    FaGraduationCap,
    FaComment,
    FaBookOpen,

} from 'react-icons/fa';
import { HiDocument } from 'react-icons/hi';
import { MdAccountCircle } from 'react-icons/md';

export const links = [
    {
        id: 1,
        url: '/dashboard',
        text: 'Home',
        icon: <FaHome className="w-5 h-5" />,
    },
    {
        id: 2,
        url: '/courses',
        text: 'Courses',
        icon: <FaGraduationCap className="w-5 h-5" />,
    },
    {
        id: 3,
        url: '/career',
        text: 'Career',
        icon: <FaBriefcase className="w-5 h-5" />,
    },
    {
        id: 4,
        url: '/messages',
        text: 'Messages',
        icon: <FaComment className="w-5 h-5" />,
    },
    {
        id: 5,
        url: '/profile',
        text: 'Profile',
        icon: <MdAccountCircle className="w-5 h-5" />,
    },
    {
        id: 6,
        url: '/lms',
        text: 'LMS',
        icon: <FaBookOpen className="w-5 h-5" />,
    },
    // {
    //     id: 5,
    //     url: '/contact',
    //     text: 'Contact',
    //     icon: <FaPhone className="w-5 h-5" />,
    // },


    // {
    //     id: 8,
    //     url: '/calendar',
    //     text: 'Calendar',
    //     icon: <FaCalendarAlt className="w-5 h-5" />,
    // },

];

export const social = [
    {
        id: 1,
        url: 'https://www.facebook.com',
        icon: <FaFacebook />,
    },
    {
        id: 2,
        url: 'https://www.twitter.com',
        icon: <FaTwitter />,
    },
    {
        id: 3,
        url: 'https://www.linkedin.com',
        icon: <FaLinkedin />,
    },
    {
        id: 4,
        url: 'https://www.behance.net',
        icon: <FaBehance />,
    },
    {
        id: 5,
        url: 'https://www.sketch.com',
        icon: <FaSketch />,
    },
];
