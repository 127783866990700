
import React from 'react';
import Dheader from './Dheader';
import JobDashboard from '../pages/JobDashboard';
import Sidebar from './Sidebar';


const JobDashbaord = () => {
    return (
        <div className="dashboard-container flex">
            <Sidebar /> {/* Render Sidebar here */}
            <div className="flex-1 p-6">
                {/* Header starts after the sidebar */}
                <div className="md:ml-64"> {/* Adjust the margin based on the sidebar's width */}
                    <Dheader />
                </div>
                <div className="md:ml-64">
                    <JobDashboard />
                </div>
            </div>
        </div>
    );
};

export default JobDashbaord;
